// 考勤-设置-考勤项
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询考勤项列表
export async function getAttendanceItem(params) {
  return request(`${ATTENDACE}/config/attendanceItem/get`, METHOD.GET, params)
}
// 修改考勤项
export async function updateAttendanceItem(params) {
  return request(`${ATTENDACE}/config/attendanceItem/update`, METHOD.POST, params)
}

// 考勤-设置-打卡方式

// 打卡类型地区分页查询
export async function clockLocationGetPageApi(params) {
  return request(`${ATTENDACE}/config/clockLocation/getLocationList`, METHOD.GET, params)
}
// 打卡类型wifi分页查询
export async function clockWifiGetPageApi(params) {
  return request(`${ATTENDACE}/config/clockWifi/getWifiList`, METHOD.GET, params)
}
// 新增地区打卡
export async function addLocationApi(params) {
  return request(`${ATTENDACE}/config/clockLocation/addLocation`, METHOD.POST, params)
}
// 新增wifi打卡
export async function addWifiApi(params) {
  return request(`${ATTENDACE}/config/clockWifi/addWifi`, METHOD.POST, params)
}
// 编辑打卡类型地区
export async function editLocationApi(params) {
  return request(`${ATTENDACE}/config/clockLocation/editLocation`, METHOD.POST, params)
}
// 编辑打卡类型wifi
export async function editWifiApi(params) {
  return request(`${ATTENDACE}/config/clockWifi/editWifi`, METHOD.POST, params)
}
// 删除地区打卡类型
export async function delLocationApi(params) {
  return request(`${ATTENDACE}/config/clockLocation/delLocation`, METHOD.POST, params)
}
// 删除wifi打卡类型
export async function delWifiApi(params) {
  return request(`${ATTENDACE}/config/clockWifi/delWifi`, METHOD.POST, params)
}

// 新增考勤组
export async function addAttendanceGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/addAttendanceGroup`, METHOD.POST, params)
}
// 编辑考勤组
export async function editAttendanceGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/editAttendanceGroup`, METHOD.POST, params)
}

// 复制考勤组
export async function copyAttendanceGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/copyAttendanceGroup`, METHOD.POST, params)
}

// 删除考勤组
export async function delAttendanceGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/delAttendanceGroup`, METHOD.POST, params)
}

// 暂无考勤组人员列表
export async function staffNotInGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/getStaffNotInGroup`, METHOD.GET, params)
}

// 暂无考勤组人员列表
export async function editStaffIgnoreTypeApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/editStaffIgnoreType`, METHOD.POST, params)
}

// 忽略和取消忽略不考勤人员
export async function getListApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/getList`, METHOD.GET, params)
}

// 考勤组新增人员
export async function addStaffInGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/addStaffInGroup`, METHOD.POST, params)
}

// 考勤组忽略和取消忽略以及新增人员
export async function editStaffIgnoreInGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/editStaffIgnoreInGroup`, METHOD.POST, params)
}

// 根据主键查询考勤组 用于数据回显
export async function getAttendanceGroupApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/getAttendanceGroup`, METHOD.GET, params)
}

// 根据主键查询考勤组详情
export async function getattendanceGroupDetailApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/getDetail`, METHOD.GET, params)
}

// 排班日历列表
export async function shiftSchedulingListApi(params) {
  return request(`${ATTENDACE}/config/shiftScheduling/list`, METHOD.GET, params)
}

// 排班日历详情
export async function shiftSchedulingDeatailApi(params) {
  return request(`${ATTENDACE}/config/shiftScheduling/detail`, METHOD.GET, params)
}

// 创建排班日历
export async function shiftSchedulingAddApi(params) {
  return request(`${ATTENDACE}/config/shiftScheduling/create`, METHOD.POST, params)
}

// 修改排班日历
export async function shiftSchedulingUpdateApi(params) {
  return request(`${ATTENDACE}/config/shiftScheduling/update`, METHOD.POST, params)
}
// 删除排班日历
export async function shiftSchedulingDeleteApi(params) {
  return request(`${ATTENDACE}/config/shiftScheduling/delete`, METHOD.POST, params)
}

